import React, { useEffect } from 'react';
import './Learn.css';

const Learn = () => {
  useEffect(() => {
    const image = document.querySelector('.sliding-image');
    let position = 0;
    const moveImage = () => {
      position += 1;
      if (position > window.innerWidth) {
        position = -200; // Reset to the left side
      }
      image.style.transform = `translateX(${position}px)`;
      requestAnimationFrame(moveImage); // Repeating the animation
    };

    moveImage();
  }, []);

  return (
    <div className="learn-more">
      <div className="sliding-image-container">
        <img
          src={require('../assets/base2.png')} // Ensure the image path is correct
          alt="Sliding"
          className="sliding-image"
        />
      </div>
      <div className="text-content">
        <h1>About BaseGems</h1>
        <p> - BaseGems was created to assist coin creators with getting the additional 
            spotlight most all meme coin projects need. Getting listed on BaseGems 
            will allow your project to stand out to more investors on Base looking 
            for serious projects who are willing to invest in marketing their idea.
            Built by Base users, for Base users.</p>
        <p> - You bring your tokens contract address, we list you on our up and 
            coming Base coin discovery app built with ReactJS. BaseGems has one 
            main goal, become a standard for all new coin projects created on Base.</p>
        <p> - BaseGems features a clean user inerface for quickly navigating listed 
            coins. Each page has filters avalible when applicable to allow for 
            you to sort Base listings by Name, MarketCap, Volume, Price Change, etc.</p>
        <p> - The five most recent base coins to be listed on BaseGems will appear 
            in the Featured Coins section. Another way BaseGems helps your coin 
            standout to investors.</p>
        <p> - One of the features that sets BaseGems apart is the Community Ranking system. This 
            allows users of the platform to cast a vote for their favorite listed 
            project. BaseGems features a Community Rankings page that sorts all the 
            listed coins by their communities power! Coins with the most votes will 
            be sorted first, coins with the least votes sorted last.</p>
        <p> - Click on the apply now button to fill out the google doc form to be listed on BaseGems. 
            Listing fee's are at 50% discount for the first week of launch as we celebrate the 
            everlasting evolution of Basegems.</p>
        <p> - BaseGems is in no way giving financial advice and all 
            users of this platform are hereby informed to do their own research 
            prior to making any finacial decision to invest in any coin listed on this platform. 
            BaseGems is not a platform that allows for direct swapping of tokens. 
            This platform sole purpose is displaying a directory of coins who have 
            made the agreement to be listed on BaseGems. BaseGems is will not be responsible for any finacial decisions you make.</p>
      </div>
      <div className="sliding-image-container">
        <img
          src={require('../assets/base2.png')} // Ensure the image path is correct
          alt="Sliding"
          className="sliding-image"
        />
      </div>
    </div>
  );
};

export default Learn;
