// src/components/Sidebar.js
import React from 'react';
import './Sidebar.css';
import flameIcon from '../assets/flame.png';
import logo from '../assets/bglogo.png';
import trophyIcon from '../assets/trophy.png';
import paperIcon from '../assets/paper.png';
import q from '../assets/q.png';
import xIcon from '../assets/x.png';

const Sidebar = ({ setPage, currentPage }) => {
  const handleApplyNowClick = () => {
    // Redirect to the desired URL
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSf5RdP5xZz4siOuwBoNPFZAQfU8ipZ0ItMxKFoT_Ak2GFkd3w/viewform'; 
  };

  return (
    <aside className="sidebar">
      <h1>BaseGems 👇</h1>
      <button onClick={() => setPage("all")} className={currentPage === "all" ? "active" : ""}><img src={logo} alt="All Coins" className="sidebar-icon" /> All Listings</button><hr />
      <button onClick={() => setPage("featured")} className={currentPage === "featured" ? "active" : ""}><img src={flameIcon} alt="Featured" className="sidebar-icon" /> Featured Gems</button><hr />
      <button onClick={() => setPage("community")} className={currentPage === "community" ? "active" : ""}><img src={trophyIcon} alt="Community Ranking" className="sidebar-icon" /> Rankings</button><hr />
      <button onClick={() => setPage("learn")} className={currentPage === "learn" ? "active" : ""}><img src={q} alt="Learn More" className="sidebar-icon" /> Learn More</button><hr />
      <button onClick={handleApplyNowClick} className={currentPage === "apply" ? "active" : ""}><img src={paperIcon} alt="Apply Now" className="sidebar-icon" /> Apply Now</button><hr />
      
      {/* Bottom 30%: Text section */}
      <br></br>
      {/* <h1>Base $GEMS 👇</h1>
      <button onClick={() => setPage("gems")} className={currentPage === "gems" ? "active" : ""}><img src={mascot} alt="Learn More" className="sidebar-icon" /> Base $GEMS</button> */}

      {/* Footer section */}
      <div className="sidebar-footer">
        <div className="footer-item">
          <img src={xIcon} alt="Icon" className="footer-icon" />
          <a href="https://x.com/basegems_io" target="_blank" rel="noopener noreferrer" className="footer-text">
            BaseGems 2024
          </a>
        </div>
        <div className="footer-item">
          <img src={xIcon} alt="Icon" className="footer-icon" />
          <a href="https://x.com/qdibs_eth" target="_blank" rel="noopener noreferrer" className="footer-text">
            qdibs
          </a>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
