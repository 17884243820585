// src/App.js
import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import PromoBox from './components/PromoBox';
import CoinTable from './components/CoinTable';
import FeaturedCoins from './components/Featured';
import Community from './components/Community'; 
import Learn from './components/Learn';
import Movers from './components/Movers'; // Import Movers component
import './App.css';

function App() {
  const [page, setPage] = useState("all");
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/basecoins.json");
      const tokens = await response.json();
      const tokenDataPromises = tokens.map(token => fetch(`https://api.dexscreener.com/latest/dex/tokens/${token}`));
      const resolvedData = await Promise.all(tokenDataPromises);
      setData(resolvedData);
    }
    fetchData();
  }, [page]);

  return (
    <div className="app">
      <Navbar setPage={setPage} currentPage={page} />
      <div className="main-content">
        <Sidebar setPage={setPage} currentPage={page} />
        <div className="content">
          {/* Insert Movers component above PromoBox */}
          <Movers />

          {/* Pass setPage as a prop to PromoBox */}
          <PromoBox setPage={setPage} />
          
          {/* Render CoinTable if the page is "all" */}
          {page === "all" && <CoinTable data={data} />}
          
          {/* Render FeaturedCoins if the page is "featured" */}
          {page === "featured" && <FeaturedCoins data={data} />} 

          {/* Render Community if the page is "community" */}
          {page === "community" && <Community data={data} />}

          {/* Render Learn if the page is "learn" */}
          {page === "learn" && <Learn data={data} />}
        </div>
      </div>
    </div>
  );
}

export default App;
