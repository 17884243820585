// src/components/PromoBox.js
import React from 'react';
import './PromoBox.css';
import promoLogo from '../assets/base.png'; // Ensure the path to your logo is correct

const PromoBox = ({ setPage }) => {
  const handleLearnMoreClick = () => {
    // Set page to "learn" when Learn More button is clicked
    setPage("learn");
  };

  const handleApplyNowClick = () => {
    // Redirect to the Apply Now URL when Apply Now button is clicked
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSf5RdP5xZz4siOuwBoNPFZAQfU8ipZ0ItMxKFoT_Ak2GFkd3w/viewform';  // Replace with your actual URL
  };

  return (
    <div className="promo-box">
      <img src={promoLogo} alt="Promo Logo" className="promo-logo" />
      <div className="promo-divider"></div> {/* Vertical divider */}
      <div className="promo-content">
        <p>Welcome to BaseGems, your destination for discovering active coins on Base! Get your project listed and allow your community to show their strength by voting. Let BaseGems help you get the exposure you deserve.</p>
        <div className="promo-buttons">
          <button onClick={handleLearnMoreClick}>Learn More</button>
          <button onClick={handleApplyNowClick}>Apply Now</button>
        </div>
      </div>
    </div>
  );
};

export default PromoBox;


